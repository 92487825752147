/* <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200" /> */
/* @import url("https://fonts.googleapis.com/css?family=Josefin+Sans"); */
@import url("https://fonts.googleapis.com/css2?family=Inconsolata:wght@300&display=swap");

:root {
  /* Light mode colors */
  --text-color: #333;
  --background-color: #fff;

  /* Dark mode colors */
  --dark-text-color: #fff;
  --dark-background-color: #333;
  --darker-background-color: #141402;
}

/* .app {
  color: var(--text-color);
  background-color: var(--background-color);
} */

.dark-mode {
  color: var(--dark-text-color);
  background-color: var(--dark-background-color);
}

.App {
  text-align: center;
  /* font-family: "Josefin Sans", cursive; */
  font-family: "Inconsolata", monospace;
}

body,
html {
  margin: 0;
  padding: 0;
  font-family: "Inconsolata", monospace;
  /* font-family: "Josefin Sans", cursive; */
  /* font-family: 'Josefin Sans', sans-serif; */
  color: var(--text-color);
  background-color: var(--background-color);
}

.modal {
  font-family: "Inconsolata", monospace !important;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

/* <style> */
.material-symbols-outlined {
  font-variation-settings: "FILL" 0, "wght" 400, "GRAD" 0, "opsz" 48;
}
/* </style> */

.card {
  color: var(--text-color);
  background-color: var(--background-color);
}

.card.dark-mode {
  color: var(--dark-text-color);
  background-color: var(--darker-background-color);
}

.modal-content {
  color: var(--text-color);
  background-color: var(--background-color);
}

.modal-content.dark-mode {
  color: var(--dark-text-color);
  background-color: var(--dark-background-color);
}

.modal-dialog.dark-mode {
  color: var(--dark-text-color);
  background-color: var(--dark-background-color);
}

.dark-modal {
  color: var(--dark-text-color);
  background-color: var(--dark-background-color);
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.glitter-effect {
  position: relative;
}

.glitter-effect::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(249, 255, 40, 0.2); /* Green color with 20% opacity */
  z-index: 2;
  animation: glitter-animation 15s linear infinite; /* Change the animation duration to 0.5s for faster blinking */
}

@keyframes glitter-animation {
  0%,
  100% {
    opacity: 0; /* Set opacity to 0 at the beginning and end of the animation */
  }
  50% {
    opacity: 1; /* Set opacity to 1 at the middle of the animation */
  }
}
